"use strict";

var _defineProperty = require("/var/www/vhosts/oyp.fr/deploy_tools/apps/calm-panda/releases/20241009104022/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var tools_1 = require("../rest-api/tools");

var defaultNormalize = function defaultNormalize(value) {
  return value;
};

exports.getOnEventChange = function (fieldName, formData, onChange) {
  var normalize = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : defaultNormalize;
  return function (event) {
    return onChange(setFieldNewValueOnFormData(fieldName, formData, normalize(event.target.value)));
  };
};

exports.getOnValueChange = function (fieldName, formData, onChange) {
  var normalize = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : defaultNormalize;
  return function (value) {
    return onChange(setFieldNewValueOnFormData(fieldName, formData, normalize(value)));
  };
};

function setFieldNewValueOnFormData(fieldName, formData, newValue) {
  var fieldPropNames = fieldName.split('.');
  var fieldObject = fieldPropNames.reverse().reduce(function (previousValue, propName) {
    return _defineProperty({}, propName, previousValue);
  }, newValue);
  return tools_1.deepAssign({}, formData, fieldObject);
}