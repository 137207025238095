"use strict";

var _defineProperty = require("/var/www/vhosts/oyp.fr/deploy_tools/apps/calm-panda/releases/20241009104022/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

Object.defineProperty(exports, "__esModule", {
  value: true
});

var stringify = require("json-stringify-pretty-compact");

var immutable_1 = require("immutable");

var react_1 = require("react");

function isDefaultProp(defaultProps, key, value) {
  if (!defaultProps) {
    return false;
  }

  return defaultProps[key] === value;
}

function stringifyObject(object, opts) {
  var result;

  if (Array.isArray(object)) {
    result = object.map(function (item) {
      return stringifyObject(item, null);
    });
  } else if (object && typeof object === 'object') {
    result = {};
    Object.keys(object).map(function (key) {
      var value = object[key];

      if (react_1.isValidElement(value)) {
        value = jsxToString(value, opts);
      } else if (Array.isArray(value)) {
        value = value.map(function (item) {
          return stringifyObject(item, opts);
        });
      } else if (typeof value === 'object') {
        value = stringifyObject(value, opts);
      } else if (typeof value === 'function') {
        value = opts.useFunctionCode ? opts.functionNameOnly ? value.name.toString() : value.toString() : "...";
      }

      result[key] = value;
    });
  } else {
    result = object;
  }

  return result;
}

var _JSX_REGEXP = /"<.+>"/g;

function serializeItem(item, options) {
  var delimit = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  var result;

  if (immutable_1.isImmutable(item)) {
    result = serializeItem(item.toJS(), options, delimit);
  } else if (typeof item === 'string') {
    result = delimit ? "'".concat(item, "'") : item;
  } else if (typeof item === 'number' || typeof item === 'boolean') {
    result = "".concat(item);
  } else if (Array.isArray(item)) {
    var indentation = new Array(options.spacing + 1).join(' ');
    var delimiter = delimit ? ', ' : "\n".concat(indentation);
    var items = item.map(function (i) {
      return serializeItem(i, options);
    }).join(delimiter);
    result = delimit ? "[".concat(items, "]") : "".concat(items);
  } else if (react_1.isValidElement(item)) {
    result = jsxToString(item, options);
  } else if (typeof item === 'object') {
    result = stringify(stringifyObject(item, options)); // remove string quotes from embeded JSX values

    result = result.replace(_JSX_REGEXP, function (match) {
      return match.slice(1, match.length - 1);
    });
  } else if (typeof item === 'function') {
    result = options.useFunctionCode ? options.functionNameOnly ? item.name.toString() : item.toString() : "...";
  }

  return result;
}

function jsxToString(component, options) {
  var baseOpts = {
    displayName: component.type.displayName || component.type.name || component.type,
    ignoreProps: [],
    ignoreTags: [],
    keyValueOverride: {},
    spacing: 0,
    detectFunctions: false
  };

  var opts = _objectSpread({}, baseOpts, {}, options); // Do not return anything if the root tag should be ignored


  if (opts.ignoreTags.indexOf(opts.displayName) !== -1) {
    return '';
  }

  var componentData = {
    name: opts.displayName
  };
  delete opts.displayName;

  if (component.props) {
    var indentation = new Array(opts.spacing + 3).join(' ');
    componentData.props = Object.keys(component.props).filter(function (key) {
      return key !== 'children' && !isDefaultProp(component.type.defaultProps, key, component.props[key]) && opts.ignoreProps.indexOf(key) === -1;
    }).map(function (key) {
      var value;

      if (typeof opts.keyValueOverride[key] === 'function') {
        value = opts.keyValueOverride[key](component.props[key]);
      } else if (opts.keyValueOverride[key]) {
        value = opts.keyValueOverride[key];
      } else if (opts.shortBooleanSyntax && typeof component.props[key] === 'boolean' && component.props[key]) {
        return key;
      } else {
        value = serializeItem(component.props[key], _objectSpread({}, opts, {
          key: key
        }));
      }

      if (typeof value !== 'string' || value[0] !== "'") {
        value = "{".concat(value, "}");
      } // Is `value` a multi-line string?


      var valueLines = value.split(/\r\n|\r|\n/);

      if (valueLines.length > 1) {
        value = valueLines.join("\n".concat(indentation));
      }

      return "".concat(key, "=").concat(value);
    }).join("\n".concat(indentation));

    if (component.key && opts.ignoreProps.indexOf('key') === -1) {
      componentData.props += "key='".concat(component.key, "'");
    }

    if (componentData.props.length > 0) {
      componentData.props = ' ' + componentData.props;
    }
  }

  if (component.props.children) {
    opts.spacing += 2;

    var _indentation = new Array(opts.spacing + 1).join(' ');

    if (Array.isArray(component.props.children)) {
      componentData.children = component.props.children.reduce(function (a, b) {
        return a.concat(b);
      }, []) // handle Array of Arrays
      .filter(function (child) {
        if (child && !react_1.isValidElement(child)) {
          return true;
        }

        var childShouldBeRemoved = child && child.type && opts.ignoreTags.indexOf(child.type.displayName || child.type.name || child.type) === -1; // Filter the tag if it is in the ignoreTags list or if is not a tag

        return childShouldBeRemoved;
      }).map(function (child) {
        return serializeItem(child, opts, false);
      }).join("\n".concat(_indentation));
    } else {
      componentData.children = serializeItem(component.props.children, opts, false);
    }

    return "<".concat(componentData.name).concat(componentData.props, ">\n") + "".concat(_indentation).concat(componentData.children, "\n") + "".concat(_indentation.slice(0, -2), "</").concat(componentData.name, ">");
  } else {
    return "<".concat(componentData.name).concat(componentData.props, " />");
  }
}

exports.jsxToString = jsxToString;